import { FacebookIcon } from 'assets/svgs/facebook'
import { InstagramIcon } from 'assets/svgs/intagram'
import { LinkedinIcon } from 'assets/svgs/linkedin'
import { Wrapper } from 'components/wrapper'
import Pdf from '../../assets/pdf/noverde.pdf'
import * as S from './styles'

export function Footer() {
  return (
    <Wrapper>
      <S.Root>
        <S.Head>
          <S.HeadMenu>
            <a
              href="https://developers.noverde.com.br/reference"
              target="_blank"
              rel="noreferrer"
            >
              Portal do Desenvolvedor
            </a>
            <a href={Pdf} target="_blank" rel="noreferrer">
              Nossas soluções
            </a>
            <a
              href="https://www.noverde.com.br/quem-somos"
              target="_blank"
              rel="noreferrer"
            >
              Sobre
            </a>
            <a href="#SaibaMais">Contato</a>
          </S.HeadMenu>
          <S.HeadIcons>
            <a
              href="https://www.facebook.com/Noverde/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/noverde/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon />
            </a>
            <a
              href="https://www.instagram.com/vempranoverde/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </S.HeadIcons>
        </S.Head>

        <S.Body>
          <S.BodyContentLeft>
            <p>NoVerde Business</p>
            <span>CNPJ 23.698.063/0001-69</span>
          </S.BodyContentLeft>
          <S.BodyContentRight>
            <S.BodyContentRightMenu>
              <label htmlFor="#QuemSomos">Sobre</label>
              <a href="#QuemSomos">Quem Somos</a>
              <a href="#OqueFazemos">O Que Fazemos</a>
              <a href="#NossaVisao">Como Inovamos</a>
            </S.BodyContentRightMenu>

            <S.BodyContentRightMenu>
              <label htmlFor="">Produtos</label>
              <a
                href="https://play.google.com/store/apps/details?id=br.com.noverde.noverde&hl=pt_BR&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <li>App Google Play</li>
              </a>
              <li>Financiamentos</li>
              <li>Empréstimos</li>
            </S.BodyContentRightMenu>

            <S.BodyContentRightMenu>
              <label htmlFor="">Desenvolvedor</label>
              <a>Documentação</a>
            </S.BodyContentRightMenu>

            <S.BodyContentRightMenu>
              <label htmlFor="">Contato</label>
              <p>
                R. Joaquim Floriano, 533, <br />
                Itaim Bibi, São Paulo - SP, <br />
                04534-011
              </p>
              <p>
                (11) 4020-1583 <br />
                meajuda@noverde.com.br
              </p>
            </S.BodyContentRightMenu>
          </S.BodyContentRight>
        </S.Body>
      </S.Root>
    </Wrapper>
  )
}
